import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Form, DatePicker, Button, Select, Space, Badge, Tag, InputNumber, Input, App,} from "antd";
import 'dayjs/locale/tr';
import {DollarOutlined, FilterOutlined, PlusOutlined, UserOutlined} from '@ant-design/icons';
import {PasswordInput} from "antd-password-input-strength";
import {ApiPost} from "../../../../../../Supports/ApiManager";
import {PaymentColors} from "../../../../../../Helper";
import MarkdownEditor from '@uiw/react-markdown-editor';
import {useRecoilState} from "recoil";
import {TimeZonesStorage} from "../../../../../../Storage/TimeZonesStorage";



const CMCreateAdmin = (props) => {

    const { message, notification, modal }                              = App.useApp();
    const [MethodAccountCreate]                                         = Form.useForm();
    const [getMethods,setMethods]                                       = useState([]);
    const [getCurrency,setCurrency]                                     = useState([]);
    const [getContentTransfer, setContentTransfer]                      = useState('');



    const onFinish = (values) => {


        ApiPost(`/card/method/create`,{
            title: values.title,
            content: values.content,
            exp: values.exp,
            amount: values.amount,
            currency: values.currency,
            status: values.status,
            prefix: values.prefix,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                message.success(response.message);
                props?.onChange(true);

            }).catch(error =>{
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                props?.onChange(true);
            })


    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    useEffect(() => {

        ApiPost(`/merchant/apps/transfer`,{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setMethods(response.result);

            }).catch(error =>{
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })


        ApiPost(`/merchant/payment/currency`,{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setCurrency(Object.keys(response.result).map(item => ({key: Math.random().toString(), label: response.result[item], value:item})));

            }).catch(error =>{
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    },[]);

    return(<>

        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

            <Form
                form={MethodAccountCreate}
                name="MethodAccountCreate"
                layout={"vertical"}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">


                <Form.Item
                    hasFeedback
                    label="Başık"
                    name="title"
                    rules={[{required: true, message: 'Bu alan zorunludur!',} ]}>
                    <Input prefix={<UserOutlined />} placeholder={"Başık giriniz"} />
                </Form.Item>
                <Form.Item
                    valuePropName={"value"}
                    hasFeedback
                    label="Card No"
                    name="content"
                    rules={[{required: true, message: 'Bu alan zorunludur!',} ]}>
                    <MarkdownEditor height="100px"  />
                </Form.Item>
                
                <Form.Item
                    valuePropName={"value"}
                    hasFeedback
                    label="Card SKT"
                    name="exp"
                    rules={[{required: true, message: 'Bu alan zorunludur!',} ]}>
                    <MarkdownEditor height="100px"  />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label="Tutar"
                    name="amount"
                    rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                    <InputNumber prefix={<DollarOutlined />} style={{width:"100%"}} stringMode placeholder={"Tutar giriniz"} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Durum"
                    name="status"
                    rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                    <Select suffixIcon={<FilterOutlined />} placeholder={"Durum seçiniz"} options={[
                        {
                            key: Math.random().toString(),
                            label:"Aktif",
                            value:"active"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Pasif",
                            value:"passive"
                        }
                    ]} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Method"
                    name="prefix"
                    rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                    <Select suffixIcon={<FilterOutlined />} placeholder={"Method seçiniz"} options={getMethods} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Para Tipi"
                    name="currency"
                    rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                    <Select suffixIcon={<FilterOutlined />} placeholder={"Method seçiniz"} options={getCurrency} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 8,
                    }}>
                    <Button icon={<PlusOutlined />} type="primary" htmlType="submit" block>
                        Ouştur
                    </Button>
                </Form.Item>


            </Form>

        </div>
    </>)

};


export default CMCreateAdmin;