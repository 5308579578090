import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Badge,
    Table,
    Tag,
    Row,
    Form,
    Col,
    Card,
    Space,
    Button,
    App,
    Tabs,
    Result,
    Skeleton,
    Input,
    Modal,
    Alert,
    DatePicker,
    Select,
} from "antd";
import {ApiPost, Server} from "../../../Supports/ApiManager";
import {useRecoilState} from "recoil";
import {CalendarOutlined, FilterOutlined, ReloadOutlined} from "@ant-design/icons";
import {SocketMerchantReceiveTransactionUpdate} from "../../../Storage/SocketMerchantReceiveTransactionUpdate";
import {isMobile} from "react-device-detect";
import dayjs from "dayjs";
import DailyFinanceWithFilter from "./DailyFinanceWithFilter";
import Marquee from 'react-fast-marquee';

const SystemReportFinance = ({height, startAt = null, finishAt = null}) => {

    const [searchForm]                          = Form.useForm();
    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);
    const { message, notification, modal }      = App.useApp();

    const [getLoading,setLoading]               = useState(false);
    const [getRecords,setRecords]               = useState([]);
    const [getDay,setDay]                       = useState([dayjs(startAt ?? new Date()),dayjs(finishAt ?? new Date())]);

    const [getMerchants,setMerchants]       = useState([]);
    const [getAgents,setAgents]             = useState([]);
    const [getMethods,setMethods]           = useState([]);
    const [getStatus,setStatus]             = useState([]);
    const [getFilters,setFilters]           = useState(null);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const exportButton          = useRef(null);

    useEffect(()=>{

        exportButton.current.setAttribute('disabled', 1);

        searchForm.setFieldsValue({
            merchant: null,
            agent: "all",
            method: "all",
            status: "all",
            date:[dayjs(new Date()),dayjs(new Date())]
        })

        ApiPost("/merchant/assigment/method",{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMethods([
                    {
                        key: Math.random().toString(),
                        label: "Hepsi",
                        value: "all"
                    }
                ]);

                response.result.map(item => setMethods(current => [...current,{
                    key: Math.random().toString(),
                    label: item.label,
                    value: item.value
                }]))


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

        ApiPost("/merchant/payment/status",{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setStatus([
                    {
                        key: Math.random().toString(),
                        label: "Hepsi",
                        value: "all"
                    },
                    {
                        key: Math.random().toString(),
                        label: "Ödeme Başarılı",
                        value: "payment_success"
                    },
                    {
                        key: Math.random().toString(),
                        label: "Ödeme Başarısız",
                        value: "payment_failed"
                    }
                ]);



            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

        ApiPost("/merchant/agents",{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setAgents([
                    {
                        key: Math.random().toString(),
                        label: "Hepsi",
                        value: "all"
                    }
                ]);

                response.result.map(item => setAgents(current => [...current,{
                    key: Math.random().toString(),
                    label: item.label,
                    value: item.value
                }]));

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

        ApiPost("/merchant/access/assigment",{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMerchants([
                    // {
                    //     key: Math.random().toString(),
                    //     label: "Hepsi",
                    //     value: "all"
                    // }
                ]);

                response.result.map(item => setMerchants(current => [...current,{
                    key: Math.random().toString(),
                    label: item.label,
                    value: item.value
                }]));
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    },[])


    const exportReport = () => {

        exportButton.current.setAttribute('disabled', 1);

        ApiPost("/payment/export/daily/system", getFilters)
            .then(response => {

                if(response.status !== "success") {
                    throw new Error(response.message);
                }

                const nowDate = new Date().toISOString();
                nowDate.substring(0, nowDate.lastIndexOf('.'))
                const filename = `export-daily-report-${nowDate}`;        
                const downloadTrigger = document.createElement("a");
                downloadTrigger.setAttribute("href", response.result.file);
                downloadTrigger.setAttribute("download", `${filename}.xls`);
                document.body.append(downloadTrigger);
                downloadTrigger.click();
                downloadTrigger.remove();

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            }).finally(() => {
                exportButton.current.removeAttribute('disabled');
            });

    };


    const getResult = (values) => {

        Modal.destroyAll();
        setLoading(true);

        setFilters({
            start_at: values.date[0].format("YYYY-MM-DD HH:mm"),
            finish_at: values.date[1].format("YYYY-MM-DD HH:mm"),
            merchant: values.merchant,
            agent: values.agent,
            status: values.status,
            method: values.method,
        });

        exportButton.current.setAttribute('disabled', 1);

        ApiPost("/payment/report/general",{
            start_at: values.date[0].format("YYYY-MM-DD HH:mm"),
            finish_at: values.date[1].format("YYYY-MM-DD HH:mm"),
            merchant: values.merchant,
            agent: values.agent,
            status: values.status,
            method: values.method,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setRecords([]);
                response.result.map(item => {
                    if(item.monitor.total > 0) {
                        exportButton.current.removeAttribute('disabled');
                    }
                    return setRecords(getRecords => [...getRecords, item]);
                });
                setLoading(false);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoading(false);
                console.error("Error : ",error);
            }).finally(() => {
            })

    }

    const columns = [
        {
            title: 'Bayi',
            dataIndex: 'merchant',
            align: 'left',
            render: (_,{merchant}) => merchant?.title
        },
        {
            title: 'İşlemler',
            dataIndex: 'sum',
            align: 'left',
            render: (_,{sum,count}) => (
                <div style={{ display:"flex", flexDirection: isMobile ? "column" : "row"}}>
                    {
                        Object.keys(sum).map(item => (
                            <div style={{width: isMobile ? "100%" : Object.keys(sum).length === 1 ? "100%" :"45%", margin:"0.5%", background: "#eaeaea", borderRadius: "6px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", alignContent: "center"}}>
                                <div style={{fontSize: ["14px", "15px"], height: "34px", width: "100%", background: "#000000", display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center", borderTopLeftRadius: "6px", borderTopRightRadius: "6px", marginBottom: "5px", color: "#FFFFFF", fontWeight: 600}}>
                                    {sum[item]["label"]}
                                </div>
                                <div style={{display: "flex", marginBottom:10, placeContent: "center space-between", alignItems: "center", flexFlow: "row", background: "rgb(64, 224, 0)", padding: "3px 13px", borderRadius: "50px", width: "95%"}}>
                                    <div>Toplam Tutar</div>
                                    <div style={{background: "white", padding: "0px 20px", borderRadius: "50px", color: "black", fontWeight: 600, letterSpacing: "2px", border: "1px dashed rgb(64 224 42)"}}>{new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: item, }).format(parseFloat(sum[item]["value"]))}</div>
                                </div>
                                <div style={{display: "flex", marginBottom:10, placeContent: "center space-between", alignItems: "center", flexFlow: "row", background: "rgb(0,0,0)", padding: "3px 13px", borderRadius: "50px", width: "95%"}}>
                                    <div style={{color:"white"}}>Toplam İşlem</div>
                                    <div style={{background: "white", padding: "0px 20px", borderRadius: "50px", color: "black", fontWeight: 600, letterSpacing: "2px", border: "1px dashed rgb(64 224 42)"}}>{count[item]["value"]}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            )
        }
    ];

    return(<>

        <Card
            extra={  
                <>
                    <div className="d-flex" style={{ display: "flex" }}>
                        <Button ref={exportButton} icon={<FilterOutlined />} type="default" style={{ marginRight: "15px" }}
                            onClick={() => exportReport() } block> Dışa Aktar </Button>
                        <Button icon={<FilterOutlined />} type="primary" onClick={() => searchForm.submit()} block> Filtrele </Button>
                        <form id="export-form" method="post" className="hidden" target="_blank"></form>
                    </div>
                </>
            }
            title={"Filtreleme"}
            style={{marginBottom:25}}>
            <Form
                form={searchForm}
                name="horizontal_login"
                layout="vertical"
                onFinish={getResult}>
                <Row gutter={25}>
                    <Col span={6}>
                        <Form.Item
                            name="merchant"
                            label="Bayi"
                            rules={[{required: true,}]}>
                            <Select placeholder="Lütfen seçim yapınız" allowClear options={getMerchants} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="agent"
                            label="Personel"
                            rules={[{required: true,}]}>
                            <Select placeholder="Lütfen seçim yapınız" allowClear options={getAgents} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="method"
                            label="Method"
                            rules={[{required: true,}]}>
                            <Select placeholder="Lütfen seçim yapınız" allowClear options={getMethods} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="status"
                            label="Ödeme Durumu"
                            rules={[{required: true,}]}>
                            <Select placeholder="Lütfen seçim yapınız" allowClear options={getStatus} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label="Tarih"
                            rules={[{required: true,}]}>
                            <DatePicker.RangePicker
                                disabledDate={(current) => {
                                    return current > dayjs().endOf('day');
                                }}
                                // onChange={(date, dateString) => {
                                //     if(dateString[0] === "" || dateString[1] === ""){
                                //         setDay([dayjs(new Date()),dayjs(new Date())])
                                //     }else{
                                //         setDay([date[0],date[1]])
                                //     }
                                // }}                                            
                                defaultValue={getDay}
                                value={getDay}
                                showTime={true}
                                style={{width:350}} format={"DD/MM/YYYY HH:mm"} />
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
        </Card>


        <Tabs items={[

            {
                key: '1',
                label: 'İşlemler',
                children: getFilters !== null ? <DailyFinanceWithFilter filtersSearch={getFilters}/> : <Alert  banner  message={"Filtreleme seçeneklerini kullanınız"} />,
            },
            {
                key: '2',
                label: 'Toplamlar',
                children: <>

                    {
                        getFilters !== null ? <>

                            {
                                !isMobile &&
                                <Table
                                    loading={getLoading}
                                    style={{ width:"100%"}}
                                    scroll={{  x:1000}}
                                    size={"small"}
                                    columns={columns}
                                    dataSource={getRecords}
                                    rowKey={"id"}
                                    bordered
                                    pagination={tableParams.pagination}
                                />
                            }

                            {
                                isMobile && <div style={{ width:"100%", height:"calc(100vh - 150px)", overflow:"scroll" }}>

                                    {
                                        getRecords.length > 0 && getRecords.map(item => {


                                            return(
                                                <div style={{marginBottom: "10px", border: "1px solid #c4b8b8", padding: "6px", borderRadius: "6px", paddingTop: "0px", paddingBottom: "0px"}}>
                                                    {
                                                        columns.map(colItem =>
                                                            <div style={{width:"100%", display:"flex", margin:"6px 0px", background:"#f3f3f3", borderRadius:6 }}>
                                                                <div   style={{padding: "6px 0px", width:130, display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                                    {colItem["title"]}
                                                                </div>
                                                                <div   style={{padding: "6px 0px", flex: "auto", display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                                    {colItem["render"](item,item)}
                                                                </div>
                                                            </div>)
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        getRecords.length === 0 && <>



                                            <Result
                                                status="warning"
                                                title="İşlem Bulunamadı"
                                            />

                                        </>
                                    }

                                </div>
                            }
                        </> : <Alert  banner  message={"Filtreleme seçeneklerini kullanınız"} />
                    }


                </>,
            }
        ]} />


    </>)

};


export default SystemReportFinance;