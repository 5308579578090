import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import { ApiPostPaymentGateway} from "../../Supports/ApiManager";
import {useTranslation} from "react-i18next";
import {Player} from "@lottiefiles/react-lottie-player";
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {App, Spin} from 'antd';
import CreditCardMethod from "./Screens/CreditCardMethod";
import TransferMethod from "./Screens/TransferMethod";
import CardMethod from "./Screens/CardMethod";
import MobileQR from "./Screens/MobileQR";
import MobileBank from "./Screens/MobileBank";
import IbanWithdraw from "./Screens/IbanWithdraw";
import success_payment from "../../Assets/lottiefiles/success_payment.json";
import failed_payment from "../../Assets/lottiefiles/failed_payment.json";
import { isMobile } from "react-device-detect";
import CreditCardWithdraw from "./Screens/CreditCardWithdraw";
import CardWithdraw from "./Screens/CardWithdraw";
import M10Withdraw from "./Screens/M10Withdraw";

const PaymentOk = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={success_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_ok_view")}</div>
    </>
}

const PaymentNO = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={failed_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_no_view")}</div>
    </>
}

const PaymentGatewayController = () => {


    const { t, i18n }                                   = useTranslation();
    let { transaction_id  }                             = useParams();
    const [getTransaction,setTransaction]               = useState({});
    const [getControl,setControl]                       = useState("initial");
    const [getControlDoneStatus,setControlDoneStatus]   = useState("");
    const [getControlDone,setControlDone]               = useState(false);
    const {message}                                     = App.useApp();
    const [isOnline, setIsOnline]                       = useState(navigator.onLine);


    const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
            if(navigator.onLine){
                getDatView();
            }else{
                console.log("Network connection failed");
            }
        };

                
    useEffect(() => {

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);
    
        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);


        window.addEventListener("focus", handleStatusChange);
    
        // Specify how to clean up after this effect for performance improvment
        return () => {
          window.removeEventListener('online', handleStatusChange);
          window.removeEventListener('offline', handleStatusChange);
            window.removeEventListener("focus", handleStatusChange);
        };
    }, [isOnline]);

    const getDatView = () => {

        ApiPostPaymentGateway(`/public/payment/transaction/${transaction_id}/view`)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                if(window?.hj !== undefined){
                    window.hj('identify', response.result.username, {
                        "TransactionID" :       response.result.transaction_id,
                        "OrderNumber" :         response.result.order_number,
                        "userName" :            response.result.username,
                    });
                }

                if(!["payment_failed","payment_success"].includes(response.result.status.value)){
                    setTransaction(response.result);
                    setTimeout(()=>setControl("success"),500)
                }else{

                    setControlDone(["payment_failed","payment_success"].includes(response.result.status.value))
                    setControlDoneStatus(response.result.status.value);

                    setTimeout(()=>setControl("error"),500)

                }

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setControl("error");
            })
    };
    useEffect(getDatView,[]);


    if(isOnline){

        if(getControl === "initial"){

            return (
                <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                    <div style={{width: (isMobile ? "90%" : 450), height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>
    
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                        <div style={{ textAlign:"center" }}>
                            <div style={{fontSize:17, fontWeight:400}}>{t("control")}</div>
                            <div style={{fontSize:15}}>{t("please_wait")}</div>
                        </div>
    
                    </div>
    
                </div>
            )
    
        }
    
    
        if(getControl === "error"){
    
            if(getControlDone){
    
                return (
                    <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                        <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>
    
                            {
                                ["payment_success_ok","payment_success"].includes(getControlDoneStatus) && <PaymentOk />
                            }
    
                            {
                                ["payment_failed","payment_failed_ok"].includes(getControlDoneStatus) && <PaymentNO />
                            }
                        </div>
    
                    </div>
    
                )
            }else{
                return (
                    <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                        <div style={{width:(isMobile ? "90%" : 450), height: "auto", padding:20, background:"#FFFFFF",   minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>
    
                            <CloseCircleOutlined style={{ fontSize: 50, color:"black", marginTop:10 }}  />
    
                            <div style={{ textAlign:"center" }}>
                                <div style={{fontSize:25, fontWeight:900, marginBottom:5}}>{t("error_title")}</div>
                                <div style={{fontSize:20}}>{t("error_payment_no")}</div>
                            </div>
    
                        </div>
    
                    </div>
                )
            }
    
        }
    
    
    
    
        if(getControl === "success" &&  getTransaction?.method?.value !== undefined){
    
           if(getTransaction?.method?.value === "MANUEL"){
    
               return (
                   <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                       <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>
    
                           <CreditCardMethod  {...getTransaction}  />
    
                       </div>
    
                   </div>
               )
    
           }else if(getTransaction?.method?.value === "MOBILE_QR"){
    
               return (
                   <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                       <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>

                           <MobileQR   {...getTransaction}  />

                       </div>
    
                   </div>
               )
    
           }else if(getTransaction?.method?.value === "CARD"){
    
            return (
                <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
 
                    <div style={{width:(isMobile ? "90%" : 450), height: "auto",  padding:"30px", background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>

                        <CardMethod   {...getTransaction}  />

                    </div>
 
                </div>
            )
 
        }else if(getTransaction?.method?.value === "IBAN_WITHDRAW"){
    
               return (
                   <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                       <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>
    
                           <IbanWithdraw   {...getTransaction}  />
    
                       </div>
    
                   </div>
               )
    
           }else if(getTransaction?.method?.value === "CREDIT_CARD_WITHDRAW"){

               return (
                   <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>

                       <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>

                           <CreditCardWithdraw   {...getTransaction}  />

                       </div>

                   </div>
               )

           }else if(getTransaction?.method?.value === "CARD_WITHDRAW"){

            return (
                <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>

                    <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>

                        <CardWithdraw   {...getTransaction}  />

                    </div>

                </div>
            )

        }else if(getTransaction?.method?.value === "M10_WITHDRAW"){

               return (
                   <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>

                       <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>

                           <M10Withdraw   {...getTransaction}  />

                       </div>

                   </div>
               )

           }else if(getTransaction?.method?.value === "MOBILE_BANK"){
    
               return (
                   <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                       <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>
    
                           <MobileBank   {...getTransaction}  />
    
                       </div>
    
                   </div>
               )
    
           }else{
               return (
                   <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>
    
                       <div style={{width:(isMobile ? "90%" : 450), height: "auto",  background:"#FFFFFF",  borderRadius:6, display:"flex", flexDirection:"column", position:"relative"}}>
    
                           <TransferMethod  {...getTransaction} />
    
                       </div>
    
                   </div>
               )
           }
    
    
    
        }
        
    }else{

        return (
            <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", background:"#EFEFEF"}}>

                <div style={{width:(isMobile ? "90%" : 450), height: "auto", padding:20, background:"#FFFFFF",   minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                    <CloseCircleOutlined style={{ fontSize: 50, color:"black", marginTop:10 }}  />

                    <div style={{ textAlign:"center" }}>
                        <div style={{fontSize:25, fontWeight:900, marginBottom:5}}>{t("error_title")}</div>
                        <div style={{fontSize:20}}>{t("error_payment_no")}</div>
                    </div>

                </div>

            </div>
        )
        
    }


};

export default PaymentGatewayController;